/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FaInstagram, FaTwitter, FaFacebookF, FaLinkedin } from "react-icons/fa"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <div className="font-sans">
        <main>{children}</main>
        <footer className="mt-24 bg-black text-white p-8 ">
          <div className="mx-auto container text-center">
            <p className="mb-8">
              Helpappy Copyright 2021. All Rights reserved.
            </p>
            <p className="mb-8">
              <Link to="/terms">Terms and Conditions</Link> |{" "}
              <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
              <Link to="/">Home</Link>
            </p>
            <p className="mb-4">
              <a
                target="_blank"
                href="https://www.instagram.com/helpappy/"
                rel="noopener"
              >
                <FaInstagram className="text-4xl inline mr-6" />
              </a>
              <a
                target="_blank"
                href="https://twitter.com/HelpappyHQ/"
                rel="noopener"
              >
                <FaTwitter className="text-4xl inline  mr-6" />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/Helpappy/"
                rel="noopener"
              >
                <FaFacebookF className="text-4xl inline" />
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
